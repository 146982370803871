// Needs to be next to get the path!
// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router'

/**
 * Gets the current path for a route. Does not cause reliable rerenders on changes!
 */
export const useRoutePath = () => {
  const nextRouter = useRouter()
  return nextRouter.pathname
}
