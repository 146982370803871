import { Fragment, ReactNode } from 'react'
import {
  YStack,
  YStackProps,
  ScrollView,
  isWeb,
  useMedia,
  isTouchable,
} from 'tamagui'
import { H1 } from './text'

export type PageProps = YStackProps & {
  scroll?: boolean
  containerSize?: 'sm' | 'md' | 'fluid'
  wrapperProps?: YStackProps
  name?: ReactNode
  framed?: boolean
  showNameOnNative?: boolean
  /**
   * If desktop is framed, this will still be added
   */
  addTopSpacing?: boolean
  /**
   * If desktop is framed, this will still be added
   */
  addBottomSpacing?: boolean
  /**
   * Add horizontal spacing to give page contents room to breathe. Use `SectionBreakout` to
   * break out of the barriers.
   *
   * @default true
   */
  addHorizontalSpacing?: boolean
}

export const getConfigForSize = (size: PageProps['containerSize']) => {
  switch (size) {
    case 'sm':
      return { maxWidth: 680, width: '100%', mx: 'auto' }
    case 'md':
      return { maxWidth: 1280, width: '100%', mx: 'auto' }
    case 'fluid':
      return { maxWidth: '100%', width: '100%', mx: 'auto' }

    default:
      throw new Error(size)
  }
}

export const Page = ({
  scroll,
  containerSize = 'md',
  children,
  wrapperProps,
  name = null,
  showNameOnNative = true,
  framed = true,
  addTopSpacing = true,
  addBottomSpacing = true,
  addHorizontalSpacing = true,
  ...rest
}: PageProps) => {
  const Component = scroll && isWeb === false ? ScrollView : Fragment
  const shouldHideBorders = containerSize === 'fluid' || framed === false
  const { gtLg } = useMedia()
  const shouldShowName = () => {
    if (!name) return false

    if (gtLg) return true

    return isTouchable ? showNameOnNative : true
  }

  return (
    <Component>
      <YStack
        backgroundColor={'white'}
        pt={addTopSpacing && '$4'}
        pb={addBottomSpacing && '$14'}
        flex={1}
        {...wrapperProps}
        {...(addHorizontalSpacing && {
          px: addHorizontalSpacing ? '$4' : '$0',
          $gtMd: { px: '$8', ...wrapperProps?.$gtMd },
        })}
        $gtLg={{
          pt: (framed || addTopSpacing) && '$9',
          pb: (framed || addBottomSpacing) && '$20',
          backgroundColor: shouldHideBorders ? 'white' : '$neutral.100',
          ...wrapperProps?.$gtLg,
        }}
      >
        <YStack
          backgroundColor={'white'}
          flex={1}
          $gtLg={{
            ...(!shouldHideBorders && {
              borderWidth: 2,
              borderColor: '$neutral.200',
              borderRadius: '$3',
              py: '$8',
              px: addHorizontalSpacing ? '$6' : '$0',
            }),
          }}
          {...getConfigForSize(containerSize)}
          {...rest}
        >
          {shouldShowName() ? (
            <H1
              mb="$2"
              size="$lg"
              $gtSm={{ size: '$2xl', mb: '$3' }}
              $gtMd={{ size: '$3xl', mb: '$4' }}
            >
              {name}
            </H1>
          ) : null}
          {children}
        </YStack>
      </YStack>
    </Component>
  )
}
