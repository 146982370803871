/* eslint-disable react/forbid-elements */
import {
  Dialog,
  YStack,
  Text,
  H4,
  XStack,
  Button,
  Link,
} from '@bounty/creators-design-system'

interface UpdatedTermsModalProps {
  isOpen: boolean
  onClose: () => void
}

export const UpdatedTermsModal = (props: UpdatedTermsModalProps) => {
  const { isOpen, onClose } = props

  return (
    <Dialog
      dialogContentProps={{
        width: '100vw',
        maxWidth: '380px',
        maxHeight: '90vh',
      }}
      open={isOpen}
      onOpenChange={onClose}
    >
      <YStack display="flex">
        <XStack
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="$3"
          pr="$6"
        >
          <H4 color="$primary.800">We've updated our Terms of Use</H4>
        </XStack>
        <YStack flex={1}>
          <Text
            size="$md"
            color="$neutral.900"
            pl="$2"
            pr="$2"
            mb="$5"
            display="inline"
          >
            Heads-up that we’ve updated our{' '}
            <Text
              size="$md"
              color="$neutral.900"
              textDecorationLine="underline"
              fontWeight="bold"
              display="inline"
            >
              <Link
                href="https://www.bounty.co/creator-terms-of-use"
                event="View terms of use link clicked"
                style={{ overflowWrap: 'anywhere' }}
              >
                terms of use.
              </Link>{' '}
            </Text>{' '}
            We’ve updated them to more accurately reflect our business today.
          </Text>
          <Text size="$md" color="$neutral.900" pl="$2" pr="$2" mb="$5">
            Your continued use of Bounty confirms that you’ve read, understood
            and accepted the updated terms.
          </Text>
        </YStack>
        <YStack>
          <Button onPress={onClose} event="Accept Terms Clicked">
            Confirm
          </Button>
        </YStack>
      </YStack>
    </Dialog>
  )
}
