import { UnreachableCaseError } from '@bounty/utils'
import * as B from './BountyCardParts'
import { useMemo } from 'react'

export const BountyPending = ({
  bounty,
  ...props
}: Omit<B.WrapperProps, 'children'>) => {
  const isBlocked = useMemo(
    () =>
      !bounty.availableToAcceptBounty?.isValid &&
      bounty.availableToAcceptBounty?.validationCode ===
        'CREATOR_BLOCKED_BY_STORE',
    [bounty],
  )

  switch (bounty.type) {
    case 'SHOPIFY_AD_HOC':
    case 'RETAIL':
      return (
        <B.Wrapper bounty={bounty} isBlocked={isBlocked} {...props}>
          <B.Body>
            <B.Header>
              <B.HeaderTitle />
              <B.HeaderStatus
                isBlocked={isBlocked}
                isExpired={bounty.isExpired}
                isAvailable={bounty.availableToAcceptBounty?.isValid}
              />
            </B.Header>
            {bounty.briefId ? (
              <B.Content isBlocked={isBlocked} />
            ) : (
              <B.BountiesAvailable />
            )}

            <B.PrimaryButton
              isBlocked={isBlocked}
              isExpired={bounty.isExpired}
              isAvailable={bounty.availableToAcceptBounty?.isValid}
            />
          </B.Body>
        </B.Wrapper>
      )
    case 'SHOPIFY_STORE_ORDER':
    case 'SHOPIFY_STORE_ORDER_GIFT':
      return (
        <B.Wrapper bounty={bounty} isBlocked={isBlocked} {...props}>
          <B.Body>
            <B.Header>
              <B.HeaderTitle />
              <B.HeaderStatus
                isBlocked={isBlocked}
                isAvailable={bounty.availableToAcceptBounty?.isValid}
                isExpired={
                  bounty.order?.status === 'DELIVERED' && bounty.isExpired
                }
              />
            </B.Header>
            {bounty.briefId ? (
              <B.Content isBlocked={isBlocked} />
            ) : (
              <B.BountiesAvailable />
            )}
            <B.PrimaryButton
              isBlocked={isBlocked}
              isAvailable={bounty.availableToAcceptBounty?.isValid}
              isExpired={
                bounty.order?.status === 'DELIVERED' && bounty.isExpired
              }
            />
          </B.Body>
        </B.Wrapper>
      )

    default:
      throw new UnreachableCaseError(bounty.type)
  }
}
