import { useAuth } from '../../hooks/useAuth'
import { useEffect } from 'react'
import { AuthBase } from '../../components/AuthBase'
import { useRouter } from 'solito/router'

export function AuthScreenBase({ isSignUpMode }: { isSignUpMode: boolean }) {
  const [{ isAuthed }] = useAuth()
  const navigate = useRouter()

  // The auth hook does not redirect on auth success to make sure deep linking works. It
  // also helps us avoid placing redirect urls in localstorage and making sure they work
  // universal. This is where the magic of the auth flow success happens!
  useEffect(() => {
    if (isAuthed) {
      navigate.push('/')
    }
  }, [isAuthed, navigate])

  return <AuthBase isSignUpMode={isSignUpMode} />
}

export function SignUpScreen() {
  return <AuthScreenBase isSignUpMode={true} />
}

export function SignInScreen() {
  return <AuthScreenBase isSignUpMode={false} />
}
