import * as B from './BountyCardParts'

export const BountyAccepted = ({
  bounty,
  ...props
}: Omit<B.WrapperProps, 'children'>) => {
  return (
    <B.Wrapper bounty={bounty} {...props}>
      <B.Body>
        <B.Header>
          <B.HeaderTitle />
          <B.HeaderStatus isExpired={bounty.isExpired} />
        </B.Header>
        <B.Content />
        <B.PrimaryButton isExpired={bounty.isExpired} />
      </B.Body>
    </B.Wrapper>
  )
}
