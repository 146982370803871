import { XStackProps } from '@bounty/creators-design-system'
import { UnreachableCaseError } from '@bounty/utils'
import { BountyOverviewPartsFragment } from '../../generated/backendGraphql'
import { BountyAccepted } from './components/Bounty.Accepted'
import { BountyApproved } from './components/Bounty.Approved'
import { BountyError } from './components/Bounty.Error'
import { BountyLive } from './components/Bounty.Live'
import { BountyPending } from './components/Bounty.Pending'
import { BountyRejected } from './components/Bounty.Rejected'
import { BountySatisfied } from './components/Bounty.Satisfied'

export type BountyCardProps = XStackProps & {
  bounty: BountyOverviewPartsFragment
}

export const BountyCard = ({ bounty, ...rest }: BountyCardProps) => {
  switch (bounty.status) {
    case 'PENDING':
      return <BountyPending bounty={bounty} {...rest} />
    case 'ACCEPTED':
      return <BountyAccepted bounty={bounty} {...rest} />
    case 'APPROVED':
      return <BountyApproved bounty={bounty} {...rest} />
    case 'ERROR':
      return <BountyError bounty={bounty} {...rest} />
    case 'SATISFIED':
    case 'HOLD':
      return <BountySatisfied bounty={bounty} {...rest} />
    case 'LIVE':
      return <BountyLive bounty={bounty} {...rest} />
    case 'REJECTED':
      return <BountyRejected bounty={bounty} {...rest} />
    default:
      throw new UnreachableCaseError(bounty.status)
  }
}
