import * as B from './BountyCardParts'

export const BountySatisfied = ({
  bounty,
  ...props
}: Omit<B.WrapperProps, 'children'>) => {
  return (
    <B.Wrapper bounty={bounty} {...props}>
      <B.Body>
        <B.Header>
          <B.HeaderTitle />
          <B.HeaderStatus />
        </B.Header>
        <B.Content />
        <B.PrimaryButton />
      </B.Body>
    </B.Wrapper>
  )
}
