import { ComponentType, ReactNode } from 'react'
import { useAuth } from '../hooks'
import { SignUpScreen } from '../screens/auth/auth'

export type RequireAuthProps = {
  children?: ReactNode
}

export const RequireAuth = ({ children }: RequireAuthProps) => {
  const [{ isAuthed, isInitializing }] = useAuth()

  if (isInitializing) return null
  if (isAuthed === false) {
    return <SignUpScreen />
  }

  // TS yells when it's just children although that's valid
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export const withAuth = (Component: ComponentType<any>) => (props: any) => {
  // TS yells when it's just children although that's valid
  return (
    <RequireAuth>
      <Component {...props} />
    </RequireAuth>
  )
}

export const ShowWhenAuthed = ({ children }: RequireAuthProps) => {
  const [{ isAuthed, isInitializing }] = useAuth()

  if (isInitializing || isAuthed !== true) return null

  // TS yells when it's just children although that's valid
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}

export const ShowWhenNotAuthed = ({ children }: RequireAuthProps) => {
  const [{ isAuthed, isInitializing }] = useAuth()

  if (isInitializing || isAuthed !== false) return null

  // TS yells when it's just children although that's valid
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
