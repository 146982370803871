import {
  Stack,
  styled,
  GetProps,
  themeable,
  TamaguiElement,
  VariantSpreadFunction,
  isWeb,
} from '@tamagui/core'
import { Text, TextProps } from './text'
import { ReactNode, forwardRef } from 'react'
import { ColorScheme } from '../themes/utils'

export type TagSize = '$xs' | '$sm' | '$md' | '$lg'
export type TagColorScheme = ColorScheme

type VariantProps = {
  size?: TagSize
  colorScheme?: TagColorScheme
}

const getTagColorScheme: VariantSpreadFunction<
  GetProps<typeof Stack> & VariantProps,
  TagColorScheme
> = (val = 'primary') => {
  return {
    backgroundColor: `$${val}.300`,
    borderWidth: 1.5,
    borderColor: `$${val}.600`,
    borderRadius: '$1',
    px: '$3',
    pressStyle: {
      backgroundColor: `$${val}.500`,
    },

    hoverStyle: {
      backgroundColor: `$${val}.400`,
    },

    focusStyle: {
      backgroundColor: `$${val}.500`,
    },
  }
}

const TagFrame = styled(Stack, {
  name: 'Tag',
  // tag: 'div',

  // if we wanted this only when pressable = true, we'd need to merge variants?
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  variants: {
    size: {
      $xs: {
        height: '$5',
        paddingHorizontal: '$1',
      },
      $sm: {
        height: '$5',
        paddingHorizontal: '$2',
      },
      $md: {
        height: '$6',
        paddingHorizontal: '$2',
      },
      $lg: {
        height: '$8',
        paddingHorizontal: '$3',
      },
    },
    colorScheme: getTagColorScheme,
  } as const,
})

const getTagTextColorScheme: VariantSpreadFunction<
  GetProps<typeof Text> & Omit<VariantProps, 'size'>,
  TagColorScheme
> = (val = 'primary') => {
  return {
    color: `$${val}.800`,
  }
}

const TagText = styled(Text, {
  name: 'TagText',
  fontWeight: '$regular',
  userSelect: 'none',
  cursor: 'pointer',
  // flexGrow 1 leads to inconsistent native style where text pushes to start of view
  flexGrow: 0,
  flexShrink: 1,
  ellipse: true,
  variants: {
    colorScheme: getTagTextColorScheme,
  } as const,
})

export type TagProps = GetProps<typeof TagFrame> &
  TextProps & {
    textProps?: GetProps<typeof TagText>
    children?: ReactNode
  }

const TagComponent = forwardRef<TamaguiElement, TagProps>(function Tag(
  { children, size = '$md', colorScheme = 'primary', textProps, ...props },
  ref,
) {
  const getTextSize = () => {
    switch (size) {
      case '$xs':
        return '$xs'
      case '$sm':
        return '$xs'
      case '$md':
        return '$sm'
      case '$lg':
        return '$md'

      default:
        throw new Error(size)
    }
  }
  return (
    // @ts-expect-error "children" should be in props
    <TagFrame
      disabled
      size={size}
      colorScheme={colorScheme}
      {...props}
      ref={ref}
      // https://www.notion.so/bountyapp/Creators-c569e372c8284c91a3da9dd9bce24be1?pvs=4#1a4052b477c945529f6f85e45de2eba9
      {...(isWeb === false && { disabled: true })}
    >
      <TagText size={getTextSize()} colorScheme={colorScheme} {...textProps}>
        {children}
      </TagText>
    </TagFrame>
  )
})

export const tagStaticConfig = {
  inlineProps: new Set([
    // text props go here (can't really optimize them, but we never fully extract tag anyway)
    // may be able to remove this entirely, as the compiler / runtime have gotten better
    'color',
    'fontWeight',
    'fontSize',
    'fontFamily',
    'letterSpacing',
    'textAlign',
  ]),
}

export const Tag = TagFrame.extractable(
  themeable(TagComponent, TagFrame.staticConfig),
  tagStaticConfig,
)
