import {
  H3,
  H3Props,
  Image as ImageCore,
  Text,
  TextProps,
  XStack,
  XStackProps,
  YStack,
  LinkButton,
  Avatar,
  Tag,
  Spinner,
} from '@bounty/creators-design-system'
import { prettyFloatingDate, UnreachableCaseError } from '@bounty/utils'
import { ReactNode, useMemo } from 'react'
import { useLink } from 'solito/link'
import { createStateContext } from '../../../factory'
import {
  BountyOverviewPartsFragment,
  SelectableBriefsDocument,
  UserBounty_Status,
} from '../../../generated/backendGraphql'
import BountyGift from '../../../assets/bounty-gift.png'
import BountyStorefront from '../../../assets/bounty-storefront.png'
import { useQuery } from '@apollo/client'

const [BountyContext, useBountyContext] = createStateContext<{
  bounty: BountyOverviewPartsFragment | null
}>({
  bounty: null,
})

const useBounty = () => {
  const [{ bounty }] = useBountyContext()
  if (!bounty) {
    throw new Error(
      'Cannot use the useBounty hook with no Bounty. Make sure the query is completed and successful!',
    )
  }

  return bounty
}

const getPrettyBountyStatus = (status: UserBounty_Status) => {
  switch (status) {
    case 'PENDING':
      return 'Ready'
    case 'ACCEPTED':
      return 'Awaiting post'
    case 'APPROVED':
      return 'Approved'
    case 'ERROR':
      return 'Not eligible'
    case 'SATISFIED':
    case 'HOLD':
      return 'In review'
    case 'LIVE':
      return 'Tracking'
    case 'REJECTED':
      return 'Not approved'
    default:
      throw new UnreachableCaseError(status)
  }
}

export const Image = () => {
  const bounty = useBounty()
  switch (bounty.type) {
    case 'RETAIL':
      return (
        <ImageCore
          flexShrink={0}
          src={BountyStorefront}
          width={90}
          height={90}
          borderRadius="$2"
        />
      )
    case 'SHOPIFY_AD_HOC':
    case 'SHOPIFY_STORE_ORDER_GIFT':
    case 'SHOPIFY_STORE_ORDER':
      return (
        <ImageCore
          flexShrink={0}
          src={bounty.product?.imgLink ?? BountyGift}
          width={90}
          height={90}
          borderRadius="$2"
        />
      )
    default:
      throw new UnreachableCaseError(bounty.type)
  }
}

export const BodyContentDate = ({
  children,
  ...props
}: Omit<TextProps, 'children'> & { children?: string }) => {
  const bounty = useBounty()

  return (
    <Text size="$xs" color="$neutral.500" {...props}>
      {prettyFloatingDate(children ?? bounty.createdAt)}
    </Text>
  )
}

export const HeaderStatus = ({
  children,
  isExpired,
  isAvailable,
  isBlocked,
  ...props
}: Omit<TextProps, 'children'> & {
  /**
   * We manually pass this in because we conditionally use this flag
   * based on bounty status + order status. Felt more readable to put
   * it at the callsites.
   */
  isExpired?: boolean
  isAvailable?: boolean
  isBlocked?: boolean
  children?: string
}) => {
  const bounty = useBounty()

  const bountyStatus = getPrettyBountyStatus(bounty.status)

  let colorScheme = 'neutral'

  switch (bountyStatus) {
    case 'Awaiting post':
    case 'Tracking':
    case 'In review':
      colorScheme = 'warning'
      break
    case 'Ready':
      colorScheme = 'success'
      break
    case 'Not approved':
      colorScheme = 'error'
      break
    default:
      break
  }

  if (isBlocked || bountyStatus === 'Not eligible')
    return (
      <Tag
        colorScheme="neutral"
        borderRadius={50}
        backgroundColor="$neutral.500"
        borderWidth={0}
        textProps={{
          color: 'white',
        }}
      >
        Not eligible
      </Tag>
    )

  return (
    <Tag
      // @ts-expect-error colorScheme string
      colorScheme={isBlocked ? 'error' : isExpired ? 'neutral' : colorScheme}
      {...props}
    >
      {children ?? isBlocked
        ? 'Not eligible'
        : isAvailable
        ? 'Ready'
        : isExpired
        ? 'Expired'
        : bountyStatus}
    </Tag>
  )
}

export type HeaderProps = XStackProps & {
  children: ReactNode
}

export const Header = (props: HeaderProps) => {
  return (
    <XStack
      space="$2"
      pb="$3"
      alignItems="center"
      justifyContent="space-between"
    >
      {props.children}
    </XStack>
  )
}

export const HeaderTitle = (props: H3Props) => {
  const bounty = useBounty()
  return (
    <XStack space="$2" alignItems="center">
      <Avatar size="$8" src={bounty.store.logoImgLink ?? undefined} />
      <H3 size="$lg" flexShrink={1} numberOfLines={1} {...props}>
        {bounty.store.name}
      </H3>
    </XStack>
  )
}

export type ContentProps = {
  isBlocked?: boolean
}

export const Content = ({ isBlocked }: ContentProps) => {
  const bounty = useBounty()

  const isBlockedOrError = isBlocked || bounty.status === 'ERROR'

  return (
    <YStack gap="$1">
      <XStack flexShrink={1} space="$2" alignItems="center">
        <H3
          size="$lg"
          flexShrink={1}
          numberOfLines={1}
          opacity={isBlockedOrError ? 0.5 : 1}
        >
          {bounty.brief?.name}
        </H3>
        {!isBlockedOrError && (
          <Tag
            colorScheme="blackAlpha"
            backgroundColor="$white"
            textProps={{ fontFamily: '$heading', py: '$1' }}
          >
            ${bounty.brief?.paymentSnapshot?.minBountyPayment ?? 0} Min
          </Tag>
        )}
      </XStack>
      <Text size="$sm" numberOfLines={2} opacity={isBlockedOrError ? 0.5 : 1}>
        {bounty.brief?.content}
      </Text>
    </YStack>
  )
}

export const BountiesAvailable = () => {
  const bounty = useBounty()

  const { data, loading } = useQuery(SelectableBriefsDocument, {
    variables: { storeId: bounty.store.id },
  })

  return (
    <XStack>
      <Tag
        mb="$1"
        colorScheme="blackAlpha"
        backgroundColor="$white"
        textProps={{ fontFamily: '$heading', py: '$1' }}
      >
        {loading ? (
          <Spinner />
        ) : (
          `  ${data?.selectableBriefs.length ?? 0} active Bounties`
        )}
      </Tag>
    </XStack>
  )
}

export type BodyContentButtonProps = {
  /**
   * We manually pass this in because we conditionally use this flag
   * based on bounty status + order status. Felt more readable to put
   * it at the callsites.
   */
  isExpired?: boolean
  isAvailable?: boolean
  isBlocked?: boolean
  children?: string
}

export const PrimaryButton = ({
  isExpired,
  isAvailable,
  isBlocked,
}: BodyContentButtonProps) => {
  const bounty = useBounty()

  const bountyStatus = getPrettyBountyStatus(bounty.status)

  if (isBlocked) return null

  if (isExpired) {
    return (
      <LinkButton
        size="$sm"
        borderRadius={50}
        mt="$3"
        href={`/bounties/${bounty.id}`}
        event="Bounty Card Request More Time Clicked"
      >
        Request more time
      </LinkButton>
    )
  }

  if (isAvailable) {
    return (
      <LinkButton
        size="$sm"
        borderRadius={50}
        mt="$3"
        href={
          bounty.briefId
            ? `/bounties/${bounty.id}/accept/content-type`
            : `/bounties/${bounty.id}/accept/select-brief`
        }
        event="Bounty Card Pick a Bounty to complete Clicked"
      >
        Pick a Bounty to complete
      </LinkButton>
    )
  }

  switch (bountyStatus) {
    case 'Ready':
      return (
        <LinkButton
          size="$sm"
          borderRadius={50}
          mt="$3"
          href={`/bounties/${bounty.id}`}
          event="Bounty Card Review Instructions Clicked"
        >
          Review instructions
        </LinkButton>
      )

    case 'Awaiting post':
      return (
        <LinkButton
          size="$sm"
          borderRadius={50}
          mt="$3"
          href={`/bounties/${bounty.id}`}
          event="Bounty Card Submit Content Clicked"
        >
          Submit content
        </LinkButton>
      )
    case 'Tracking':
    case 'In review':
    case 'Approved':
      return (
        <LinkButton
          size="$sm"
          borderRadius={50}
          mt="$3"
          href={`/bounties/${bounty.id}`}
          event="Bounty Card View Details Clicked"
        >
          View details
        </LinkButton>
      )
    case 'Not approved':
      return (
        <LinkButton
          size="$sm"
          borderRadius={50}
          mt="$3"
          href={`/bounties/${bounty.id}`}
          event="Bounty Card Try again Clicked"
        >
          Try again
        </LinkButton>
      )
    default:
      return null
  }
}

export const Body = (props: XStackProps) => {
  return (
    <YStack
      backgroundColor={'$neutral.50'}
      flexGrow={1}
      borderColor="$neutral.200"
      borderWidth={1}
      borderRadius="$2"
      p="$4"
      flexShrink={1}
      {...props}
    />
  )
}

export type WrapperProps = XStackProps & {
  bounty: BountyOverviewPartsFragment
  isBlocked?: boolean
  children: ReactNode
}

export const Wrapper = ({
  bounty,
  isBlocked = false,
  children,
  ...rest
}: WrapperProps) => {
  const linkProps = useLink({
    href: bounty.availableToAcceptBounty?.isValid
      ? bounty.briefId
        ? `/bounties/${bounty.id}/accept/content-type`
        : `/bounties/${bounty.id}/accept/select-brief`
      : `/bounties/${bounty.id}`,
  })

  const memoContext = useMemo(
    () => ({
      bounty: bounty,
    }),
    [bounty],
  )

  const isBlockedOrError = isBlocked || bounty.status === 'ERROR'

  return (
    <BountyContext shouldUpdateOnInitialValueChange initialValue={memoContext}>
      <XStack
        cursor={isBlockedOrError ? 'not-allowed' : 'pointer'}
        space="$4"
        data-testid={`bounty:${bounty.id}`}
        {...(!isBlockedOrError && linkProps)}
        {...rest}
      >
        {children}
      </XStack>
    </BountyContext>
  )
}
