import { useMutation, useQuery } from '@apollo/client'
import { ReactNode, useEffect } from 'react'

import {
  RequireUpdatedTermsDocument,
  TermsAcceptedDocument,
} from '../../generated/backendGraphql'
import { useAuth } from '../../hooks'
import { useDisclosure } from '@chakra-ui/react'
import { UpdatedTermsModal } from './UpdatedTermsModal'

interface RequireUpdatedTermsModalProps {
  children: ReactNode
}

export const RequireUpdatedTermsModal = (
  props: RequireUpdatedTermsModalProps,
) => {
  const { children } = props
  const { data } = useQuery(RequireUpdatedTermsDocument)
  const [acceptTerms] = useMutation(TermsAcceptedDocument, {
    update(cache) {
      cache.modify({
        id: `User:${data?.currentUser.id}`,
        fields: {
          acceptedLatestTerms() {
            return true
          },
        },
      })
    },
  })
  const [{ isAuthed }] = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const isLatestTermsAccepted = data?.currentUser?.acceptedLatestTerms

  useEffect(() => {
    if (
      isAuthed &&
      !isLatestTermsAccepted &&
      isLatestTermsAccepted !== undefined
    ) {
      onOpen()
    }
  }, [isAuthed, isLatestTermsAccepted, onOpen])

  return (
    <>
      {children}
      {isOpen && (
        <UpdatedTermsModal
          isOpen={isOpen}
          onClose={() => {
            acceptTerms({
              variables: {
                termName: 'inactivity_fee',
              },
            })
            onClose()
          }}
        />
      )}
    </>
  )
}
