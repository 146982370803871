/* eslint-disable react/forbid-elements */
import {
  Dialog,
  YStack,
  Text,
  H4,
  XStack,
  Button,
} from '@bounty/creators-design-system'
import { IoClose } from 'react-icons/io5'

interface WelcomeFlowModalProps {
  isOpen: boolean
  onClose: () => void
}

export const WelcomeFlowModal = (props: WelcomeFlowModalProps) => {
  const { isOpen, onClose } = props

  const welcomeFlowContent = [
    {
      title: '1. Claim a Bounty',
      description: 'Then grab the brand’s products online or in-store',
    },
    {
      title: '2. Create an awesome video',
      description:
        'Brands are paying for content they can use, so make sure you follow their requirements and prompt to earn rewards',
    },
    {
      title: '3. Earn cash!',
      description:
        'You get paid for engagement + quality! Rewards will be calculated after 48 hours',
    },
  ]

  return (
    <Dialog
      dialogContentProps={{
        width: 500,
        maxWidth: '90vw',
        maxHeight: '90vh',
        bg: '$neutral.200',
      }}
      open={isOpen}
      onOpenChange={() => onClose()}
      customCloseComponent={
        <XStack onPress={() => onClose()} width="$6" cursor="pointer">
          <IoClose size="$5" fontWeight="bold" color="$neutral.600" />
        </XStack>
      }
    >
      <YStack display="flex">
        <XStack
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="$3"
        >
          <H4 color="$primary.800">
            {' '}
            3 easy steps to <H4 color="$primary.500">get paid</H4>
          </H4>
        </XStack>
        <YStack flex={1}>
          {welcomeFlowContent.map(({ title, description }) => (
            <YStack paddingBottom="$3">
              <Text size="$lg" color="$primary.500" fontWeight="bold" mb="$1">
                {title}
              </Text>
              <XStack
                borderRadius="$2"
                paddingHorizontal="$4"
                paddingVertical="$3"
                bg="white"
              >
                <Text size="$md">{description}</Text>
              </XStack>
            </YStack>
          ))}
        </YStack>
        <YStack>
          <Button
            onPress={() => onClose()}
            event="Complete Welcome Flow Clicked"
          >
            I'm ready!
          </Button>
          <Text mt="$4" size="$sm" color="$neutral.600">
            By accepting payment through Bounty you give brands and Bounty the
            right to use your content.
          </Text>
        </YStack>
      </YStack>
    </Dialog>
  )
}
