import { useMutation, useQuery } from '@apollo/client'
import { ReactNode, useEffect } from 'react'

import {
  CompleteWelcomeFlowDocument,
  RequireWelcomeFlowDocument,
} from '../../generated/backendGraphql'
import { useAuth } from '../../hooks'
import { useInvite } from '../../hooks/useInvite/useInvite'
import { useRoutePath } from '../../hooks/useRoutePath/useRoutePath'
import { useDisclosure } from '@chakra-ui/react'
import { WelcomeFlowModal } from './WelcomeFlowModal'

interface RequireWelcomeModalProps {
  children: ReactNode
}

export const RequireWelcomeModal = (props: RequireWelcomeModalProps) => {
  const { children } = props
  const location = useRoutePath()
  const [{ ref }] = useInvite()
  const { data } = useQuery(RequireWelcomeFlowDocument)
  const [completeWelcomeFlow] = useMutation(CompleteWelcomeFlowDocument)
  const [{ isAuthed }] = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()

  // Redirecting in render because if you do it inside of an effect you will receive a flash
  // of the home page before redirecting
  const isWelcomePath = location.includes('/welcome')
  const isRetail = ref === 'RETAIL'
  const isWelcomeFlowCompleted = data?.currentUser.isWelcomeFlowCompleted

  if (isRetail) {
    completeWelcomeFlow()
  }

  useEffect(() => {
    if (
      isWelcomeFlowCompleted !== undefined &&
      !isWelcomeFlowCompleted &&
      !isWelcomePath &&
      !isRetail &&
      isAuthed
    ) {
      onOpen()
    }
  }, [isAuthed, isWelcomePath, isRetail, isWelcomeFlowCompleted, onOpen])

  return (
    <>
      {children}
      {isOpen && (
        <WelcomeFlowModal
          isOpen={isOpen}
          onClose={() => {
            completeWelcomeFlow()
            onClose()
          }}
        />
      )}
    </>
  )
}
